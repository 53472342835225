// TODO: Get rid of the "views" wrapper entirely and deduplicate "company"
/* eslint-disable quote-props */
/* eslint-disable max-len */

export const en = {
  usercategories: {
    heading: "User categories",
    usercategory: "User category",
    default: "Default",
    name: "Name",
    "confirm-delete":
      "Do you really want to delete this user category? Users with this category will be transferred to the default category.",
    "button-create-new": "Create a new user category",
  },
  tagInput: {
    "editing-keyword": "Editing keyword",
    "update-keyword": "Update keyword",
    "delete-keyword": "Delete keyword",
    disabled: "Disabled",
  },
  fields: {
    info: "Info",
    phase: "Phase",
    subject: "Subject",
    creation_date: "Creation date",
    position: "Position",
    gps_coordinates: "GPS coordinates",
    gps_accuracy: "GPS accuracy",
    state: "State",
  },
  // TODO: move these to more suitable places
  documentType: {
    manageTitle: "Manage Document Types & Fields",
    defaultDocumentType: "Document",
    keywords: "Keywords",
    text: "Text",
    date: "Date",
    "create-document-type": "Create new document type",
    name: "Name",
    "delete-type": "Delete document type",
    "confirm-delete": "Are you sure that you want to delete this document type?",
    update: "Update",
    "enable-disable-fields": "Enable/disable fields",
    "field-name": "Field name",
    "field-type": "Field type",
    enabled: "Enabled",
    default: "Default",
    true: "True",
    false: "False",
    "update-fields": "Update fields",
    "load-fields": "Load fields...",
    return: "Return",
    "manage-fields": "Manage fields",
    "select-type": "Select type",
    actions: "Actions",
    "manage-document-types": "Manage document types",
    view: "View",
    create: "Create",
    "enter-keyword": "Enter a keyword and press enter",
    active: "Active",
    "document-type": "Document Type",
    "show-in-documents": "Show in documents table",
  },
  constants: {
    companyTypes: {
      subcontractor: "Subcontractor",
      client: "Client",
      owner: "Owner",
    },
    userTypes: {
      Super: "Superadmin",
      employee: "Employee",
      manager: "Manager",
      editor: "Editor",
      client: "Client",
      subcontractor: "Subcontractor",
      owner: "Owner",
    },
  },
  permissions: {
    permissions: "Permissions",
    "p:defaultfolder_manage": "Defaultfolders manage",
    "p:projects_manage": "Projects manage",
    "p:document_edit": "Document edit",
    "p:documents_delete": "Document delete",
    "p:users_manage": "Users manage",
    "p:companies_manage": "Companies manage",
    "p:roles_manage": "Roles manage",
    "p:import": "Import",
    "p:document-delete-requests_manage": "Document delete requests manage",
    "p:keywords_manage": "Keywords manage",
    "p:condominiums_manage": "Condominiums Manage",
    "p:products_manage": "Products Manage",
    "p:permissions_manage": "Permissions Manage",
    "p:schedules_view": {
      full: "View all levels of a schedule",
    },
    "p:notification_access": "Notifications manage",
    "p:notification_create": "Notifications create",
    "p:notification_edit": "Notifications edit",
    "p:notification_delete": "Notifications delete",
    contentpermission: {
      types: {
        "App\\Project": "Project",
        "App\\Folder": "Folder",
      },
    },
  },
  general: {
    appname: "Avainia",
    deleted: "Deleted",
    roles: "Roles",
    document: "Document",
    user: "User",
    users: "Users",
    inactiveusers: "Inactive Users",
    id: "ID",
    email: "Email",
    "new-password": "New Password",
    loading: "Loading",
    errormessage: "An error happened during the operation. Error code: {{error}}.",
    password: "Password",
    password_confirmation: "Password confirmation",
    configure: "Configure",
    "table-actions": "Actions",
    companies: "Companies",
    apartment: "Apartment",
    "modal-close": "Close",
    accept: "Accept",
    reject: "Reject",
    cancel: "Cancel",
    save: "Save",
    return: "Return",
    "modal-form-save": "Save",
    areasqm: "Area (sqm)",
    price: "Price",
    "total-price": "Total",
    basicdata: "Basic data",
    status: "Status",
    inactive: "Inactive",
    active: "Active",
    address: "Address",
    "no-results": "No results",
    "no-folders": "No folders",
    edit: "Edit",
    "privacy-policy": "Privacy policy",
    terms: "Terms",
    "choose-photo": "Choose photo",
    "choose-context-photo": "Choose context photo",
    "remove-photo": "Remove photo",
    create: "Create",
    "save-changes": "Save changes",
    delete: "Delete",
    default: "Default",
    "avainiatable-no-data-text": "There is no data to display",
    select: "Select",
    today: "Today",
    "password-reset-match-error": "The passwords don't match",
    "password-reset-invalid": "The password isn't strong enough",
    required: "* This field is required",
    "button-cancel": "Cancel",
    "button-save": "Save",
    confirm: "Confirm",
    "document-amount": "Documents",
    "document-created": "Most recent document",
  },
  defaultfolders: {
    name: "Name",
    parent: "Parent",
    explanation:
      "Project defaultolders have their own independent permission system, and their permissions cannot be maintained on a per-project basis.",
    "create-new-child": "Create a new defaultfolder as a subfolder for this defaultfolder",
    "create-defaultfolder": "Create a new defaultfolder",
    "button-create-new": "Create a new top level defaultfolder",
    "manage-defaultfolder": "Manage defaultfolder",
    "confirm-delete": "Are you sure you want to permanently delete this defaultfolder?",
    "project-subtypes-and-default-folders": "Project types and default folders",
    "edit-defaultfolder-name": "Edit defaultfolder name",
    "edit-defaultfolder-permissions": "Edit defaultfolder permissions",
  },
  projectSubtypes: {
    "project-subtypes-select": "Select project type",
    "project-subtypes-create": "Create project type",
    "project-subtypes-name": "Project type name",
    "project-subtype": "Project type",
    "project-subtypes-edit": "Edit project type",
  },
  projectStatuses: {
    "project-statuses": "Project statuses",
    "project-status-tree": "Project status tree",
    "edit-status": "Edit status",
    active: "Active",
    "click-to-deactivate": "Click to deactivate this status",
    "click-to-activate": "Click to activate this status",
    "create-new-status": "Create new status",
    "status-slug": "Status code",
    "status-name": "Status name",
    "status-parent": "Parent status",
    "status-active": "Is status active",
    "status-active-in-projects": "This status is currently in use in following projects:",
    "confirm-delete": "Are you sure you want to delete this status?",
    "select-by-status": "Select by project status",
  },
  folders: {
    defaultfolders: "Project default folders",
    enabled: "Folders enabled",
    disabled: "Folders disabled",
    "confirm-delete":
      "Are you sure you want to permanently delete this folder? Only empty folders can be deleted. Default folders cannot be deleted.",
    folder: "Folder",
    folders: "Folders",
    "files-in-subfolders": "Files in subfolders",
    "warning-visibility":
      "Note that all files that are not in a folder are always visible to all users that can access the project!",
    "please-select-company": "Please select a company to manage folder visibility for that company.",
    "click-to-grant-permission": "Click to grant permission",
    "click-to-revoke-permission": "Click to revoke permission",
    filecount: "Files",
    mostRecentDocument: "Most recent document",
    subfolders: "Subfolders",
    parentFolder: "Parent folder",
    name: "Name",
  },
  user: {
    roles: "Roles",
    role: "Role",
  },
  documents: {
    deleteRequests: "Document delete requests",
    deleteRequest: "Document delete request",
    deleteRequestRequestedBy: "Requested by",
    confirmAcceptDeleteRequest: "Are you sure you want to accept the delete request?",
    confirmRejectDeleteRequest: "Are you sure you want to reject the delete request?",
    deleteRequestDeleteSelected: "Accept selected delete requests",
  },
  search: {
    "no-results": "No results",
    "search-result": "Search result",
    "search-results": "Search results",
    "search-results-for": "Search results for",
    documents: "Documents",
    project: "Project",
    projects: "Projects",
    "download-file": "Download file",
    "move-to-project": "Move to project",
    close: "Close",
    page: "Page",
    search: "Search",
    "advanced-search": "Advanced search",
  },
  views: {
    summaryviews: {
      addsummaryview: "Add new summary view",
      confirmdelete: "Are you sure you want to delete this summary view?",
      subcontractor: "Subcontractor",
    },
    progressnumbers: {
      progressnumbers: "Work progress",
      usedpetrol: "Used petrol",
      cabletrenchdig: "Dug cable trench",
      laidcable: "Laid cable",
      cablecoverinstalled: "Installed cable covering",
      eleccabinetinstalled: "Installed electrical cabinets",
      transinstalled: "Installed transformers",
      wedtileinstalled: "Installed wedding tiles",
      polesinstalled: "Installed electrical poles",
      directdrilling: "Directional drillings",
      asphaltinstalled: "Installed asphalt or grass",
      kpl: "Pieces",
      m: "Meters",
      l: "Liters",
      m2: "Square meters",
      addreport: "Add report",
      progressreports: "Progress reports",
      timeperiod: "Select a time period",
      amount: "Amount",
      tasks: "Tasks",
      addtasks: "Add tasks",
      confirmreportdelete: "Are you sure you want to delete this progress report?",
    },
    documents: {
      documents: "Documents",
      type: "Type",
      "select-type": "Select document type",
      next: "Next",
      "replace-file": "Replace file",
      "choose-new-file": "Select a new file",
      "current-file": "Current file",
      "new-file": "New file",
      "no-preview-text": "No image preview available",
      "group-name": "Group name",
    },
    sidebar: {
      "language-selector": "Select language",
      "link-admin": "Default folders",
      "link-projects": "Projects",
      "link-projects-inactive": "Project Archive",
      "link-projects-admin": "Project management",
      "link-apartments": "Apartments",
      "link-condominiums": "Condominiums",
      "link-privacy": "Privacy",
      "link-translations": "Translations",
      "link-logout": "Log out",
      "link-products": "Products",
      "link-permissions": "Permissions",
      "link-companies": "Companies",
      "link-company-management": "Company management",
      "user-photo-alt": "User",
      "document-types": "Document types",
      notifications: "Notifications",
      sharedsummaries: "Shared summary pages",
      mobilelinks: "App links",
      userphotomodal: {
        title: "Change user photo",
        button: "Change",
        "photo-preview": "Preview",
      },
    },
    materialforms: {
      materialforms: "Material selection forms",
      materialform: "Material selection form",
      "link-matform-to-apt-template": "Attach material selection froms to apartment templates",
      "materialform-name": "Name",
      "deadlines-for": "Materialform deadlines for",
      deadlines: "Deadlines",
      deadline: "Deadline",
      "button-add-link": "Link template to materialform",
      "button-remove-link": "Remove template to materialform link",
      "button-add-option": "Add option",
      "button-add-selection": "Add selection",
      "materialform-add-new-option": "Add new option",
      "materialform-add-new-group": "New group",
      "materialform-add-new-select": "New selection",
      "materialform-group-name": "Group name",
      "materialform-group": "Group",
      "materialform-selection": "Selection",
      "materialform-select-choose-productcategory": "Select product category",
      "materialform-option": "Option",
      "materialform-options": "Options",
      "create-new-materialform": "Create new Materialform",
      "default-option": "Default option",
      "button-create": "Create",
      "button-cancel": "Cancel",
      "button-save": "Save",
      "button-add-group": "Add group",
      "button-quote-create": "Create quote",
      "button-quote-show": "View quote",
      "button-create-quote": "Create quote draft",
      "button-save-quote": "Save quote",
      "button-publish-quote": "Publish quote",
      "button-view-quote": "Show material selection quote",
      "button-approve-quote": "Approve quote",
      "button-show-product-report": "Show product report",
      "selections-done": "Selections done",
      quotes: "Material selection quotes",
      "quotes-heading": "Material selection quotes",
      "quote-name": "Name",
      "quote-status-created": "Status: created",
      "quote-status-unsaved": "Status: unsaved",
      "quote-status-sent": "Status: sent",
      "quote-status-approved": "Status: approved",
      "edit-title": "Materialform editor",
      "confirm-delete": "Are you sure you want to delete?",
      "link-matform-to-template": "Link materialform to apartment template",
      "apartment-materialform-quote": "Apartment materialform quote",
      "confirm-link":
        "Are you sure you want to activate the selected material form for the selected apartment template?",
      "confirm-unlink":
        "Are you sure you want to deactivate the selected material form for the selected apartment template?",
      "confirm-deadline-delete": "Are you sure you want to delete the selected materialforms deadline?",
    },
    contacts: {
      contacts: "Contact persons",
      "create-contact": "Create new contact person",
      "button-create": "Create",
      "button-cancel": "Cancel",
      "button-save": "Save",
      "create-contact-first_name": "Firstname",
      "create-contact-last_name": "Lastname",
      "create-contact-title": "Title",
      "create-contact-phone": "Phone",
      "edit-contact": "Edit contact",
      "confirm-contact-delete": "Are you sure you want to delete this contact?",
    },
    schedules: {
      schedules: "Schedules",
      "button-create": "Create",
      "button-cancel": "Cancel",
      "button-save": "Save",
      "edit-schedule": "Edit schedule",
      "create-schedule": "Create new schedule",
      "create-schedule-description": "Description",
      "create-schedule-start_at": "Starting at",
      "create-schedule-end_at": "Ending at",
      "create-schedule-estimate_start_at": "Estimated starting at",
      "create-schedule-estimate_end_at": "Estimated ending at",
      "create-schedule-parent_id": "Parent id",
      "create-schedule-completion": "Completion percentage",
      schedule: "Schedule",
      name: "Name",
      start: "Start",
      end: "End",
      "estimate-start": "Estimated start time",
      "estimate-end": "Estimated end time",
      completed: "Completed",
    },
    products: {
      productcategories: "Product categories",
      productcategory: "Product category",
      products: "Products",
      "products-for": "Products for category",
      "create-new-product": "Create new product",
      "create-new-product-category": "Create new product category",
      name: "Name",
      manufacturer: "Manufacturer",
      code: "Product code",
      description: "Description",
      price: "Price",
      measurements: "Measurements",
      external_link: "External link",
      "button-create": "Create",
      "button-save": "Save",
      "edit-product-category": "Edit product category",
      "product-category-label": "Category name",
      "edit-product": "Edit product",
      "productname-label": "Name",
      "productmanufacturer-label": "Manufacturer",
      "productcode-label": "Code",
      "productmeasurements-label": "Measurements",
      "productdescription-label": "Description",
      "productexternallink-label": "External link",
      "productprice-label": "Price",
      "product-photo-label": "Photo",
      "product-context-photo-label": "Context photo",
    },
    projects: {
      "confirm-delete": "Are you sure you want to delete this project and its data?",
      projects: "Projects",
      "projects-archive": "Project Archive",
      project: "Project",
      condominiumproject: "Condominiumproject",
      "condominiumproject-needs-project":
        "All condominiumprojects require a set condominium, please choose one for this project below.",
      "project-code": "Identifier",
      "project-name": "Name",
      type: "Type",
      condominium: "Condominium",
      infrastructure: "Infrastructure",
      marine: "Marine",
      "create-new-project": "Create new project",
      "warn-select-option-first": "Please make a selection",
      "button-create": "Create new project",
      "search-projects": "Search from projects",
      "edit-project": "Edit project",
      "configure-view": "Configure view",
      "project-coordinates": "Project coordinates",
      "csv-import": "Import from CSV",
      loading: "Loading projects",
      table: {
        "show-id": "Show ID",
        "show-code": "Show identifier",
        "show-name": "Show name",
        "show-status": "Show status",
        "show-companies": "Show companies",
        "show-type": "Show type",
        "show-actions": "Show actions",
        "show-inactive": "Show inactive projects",
      },
      types: {
        condominium: "Condominium",
        infrastructure: "Infrastructure",
      },
      companies: {
        /* TODO: Delete! */ companies: "Companies",
        name: "Name",
        code: "Code",
        type: "Type",
        search: "Search for a company",
        "add-company": "Add company",
        "attach-company": "Attach company",
        "attached-company": "Attached company",
        "add-user": "Add user",
        "edit-company": "Edit company",
        "edit-user": "Edit user",
        "company-users": "Company users",
        "company-info": "Company info",
        phone: "Phone",
      },
      status: "Status",
      "status-disabled": "Inactive",
      "project-statuses": "Project Statuses",
      "project-status-changed": "Project status changed",
      statuses: {
        active: "Active",
        inactive: "Inactive",
        before: "Not started",
        during: "Started",
        after: "Finished",
        imported: "Imported",
        archived: "Archived",
      },
    },
    login: {
      "please-enter-new-password": "Please enter a new password for your account below.",
      "enter-email-for-reset": "Please enter your email address and a password reset link will be sent for you.",
      "forgot-password": "Forgotten username or password?",
      "click-here": "Click here",
      "login-button": "Login",
      "invalid-credentials": "Check email and password",
      "privacy-policy-1": "Read the",
      "privacy-policy-2": "privacy policy",
    },
    passwordset: {
      title: "Set password",
      "message-success": "Save successful, you can now log in using the password you provided.",
      "message-check-input": "Check inputs",
      "message-mismatched-input": "Passwords do not match",
      "button-save": "Save",
    },
    condominiums: {
      condominiums: "Condominiums",
      condominium: "Condominium",
      stairwell: "Stairwell",
      "apartment-number": "Apartment number",
      apartmenttemplates: "Apartment templates",
      apartmenttemplate: "Apartment template",
      "edit-apartmenttemplate": "Edit apartment template",
      "apartmenttemplate-name": "Template name",
      "add-new-apartmenttemplate": "Add new apartment template",
      "button-create-apt-template": "Create new apartment template",
      "button-delete": "Delete",
      name: "Name",
      street_address: "Street address",
      postcode: "Postcode",
      post_office: "Post office",
      "button-create-new": "Create new condominium",
      "button-edit": "Edit condominium",
      "tab-basedata": "Basic information",
      "tab-apartments": "Apartments",
      "confirm-delete": "Are you sure you want to delete this condominium?",
      apartments: {
        apartment: "Apartment",
        "button-create": "Create",
        stairwell: "Stairwell",
        apartment_number: "Apartment number",
        apartment_template_id: "Apartment template",
      },
      users: {
        title: "Users",
        "edit-user": "Edit user",
        "add-user": "Add user",
        name: "Name",
        code: "Code",
        phone: "Phone",
        type: "Type",
        add: "Add user",
        search: "Search",
        specifier: "Specifier",
      },
    },
    companies: {
      companies: "Companies",
      name: "Name",
      code: "Code",
      type: "Type",
      search: "Search for a company",
      "add-company": "Add company",
      "attach-company": "Attach company",
      "add-user": "Add user",
      "edit-company": "Edit company",
      "manage-company": "Manage company",
      "edit-user": "Edit user",
      "edit-self-warning": "Note: you are editing your own account.",
      "company-users": "Company users",
      "company-info": "Company info",
      phone: "Phone",
      "user-add": "Add new user",
      "reset-password": "Reset password",
      "reset-password-email-sent": "An email was sent to the user containing a password reset link.",
      "reset-password-confirm": "Are you sure you want to send a password reset link to the user?",
      "new-password": "New password",
      "set-new-password": "Set new password",
      "new-password-success": "Password changed successfully!",
      "new-password-empty-warning": "New password cannot be empty",
      "csv-import": "Import from CSV",
    },
    permissions: {
      "confirm-permission": "Are you sure you want to grant permission {{permission}} to role {{role}}?",
      "confirm-permission-revoke": "Are you sure you want to revoke permission {{permission}} from role {{role}}?",
      "tab-permissions": "Permissions",
      "tab-createpermission": "Create new permission",
      permissions: "Permissions",
      "table-target": "Target",
      "table-specifier": "Specifier",
      "table-user-group": "User group",
      "table-permission": "Permission",
      "button-new-permission": "New permission",
      "create-new-permission": "Create new permission",
      "create-permission-target": "Target",
      "create-permission-specifier": "Specifier",
      "create-permission-usergroup": "User group",
      "create-permission-permission": "Permission",
      "edit-permission": "Edit permission",
      "new-usergroup-label": "Or",
      "create-new-usergroup": "Create new user group",
      "usergroup-exists-error": "User group already exists",
      "input-empty-error": "User group input can't be empty",
      "create-permission-error": "Select value from every dropdown",
      search: "Search permission",
      "target1-tooltip": "Target to which the user group is associated",
      "target2-tooltip": "Target to which the permission applies",
    },
    infraproject: {
      addedByCompany: "Company",
      filename: "Filename",
      "add-document": "Add document",
      "select-files": "Select files",
      "max-files": "Maximum file count: ",
      "add-folder": "Add folder",
      displaying: "Displaying",
      "configure-view": "Configure view",
      filter: "Filter",
      "filter-documents": "Filter documents",
      "filter-clear": "Clear filters",
      info: "Info",
      nbr: "NBR",
      company: "Company",
      download: "Download",
      delete: "Delete",
      edit: "Edit",
      location: "Location",
      "coordinate-accuracy": "Coordinate accuracy",
      position: "Position",
      phase: "Phase",
      phases: "Phases",
      date: "Date",
      settings: "Settings",
      subject: "Subject",
      subjects: "Subjects",
      state: "State",
      states: "States",
      created: "Created at",
      "toggle-mapview": "Toggle map view",
      "show-map": "Show map",
      "hide-map": "Hide map",
      "expand-map": "Expand map",
      "collapse-map": "Collapse map",
      "full-screen-map": "Full-screen mode",
      "collapse-full-screen-map": "Exit full-screen mode",
      "no-coloring": "No coloring",
      "color-by-company": "Color by company",
      "color-by-subject": "Color by subject",
      inactive: "Inactive",
      "add-document-failure": "Image adding failed",
      "add-folder-failure": "Folder adding failed",
      "choose-a-phase": "Choose a phase",
      "choose-a-subject": "Choose a subject",
      "choose-a-file": "Choose a file",
      "mode-table": "TABLE",
      "mode-icons": "ICONS",
      "mode-reports": "REPORTS",
      "sort-by-date": "Sort by date",
      "sort-by-type": "Sort by type",
      "show-company": "Show company",
      "show-name": "Show name",
      "show-info": "Show info",
      "show-position": "Show position",
      "show-phase": "Show phase",
      "show-subject": "Show subject",
      "show-state": "Show state",
      "confirm-cancel-changes": "Do you want to cancel the changes you made?",
      "confirm-document-delete": "Do you want to delete the selected document?",
      "confirm-documents-delete": "Do you want to delete the selected documents?",
      "error-saving-check-phase": "Error saving - check phase",
      "error-saving-check-subject": "Error saving - check subject",
      "save-success": "Successful save",
      "delete-success": "Successful delete",
      "button-add-document": "Add new document",
      "button-add-folder": "Add new folder",
      "button-show-details": "Show details",
      "button-download": "Download",
      "button-edit": "Edit",
      "button-edit-coordinates": "Edit coordinates",
      "export-selected-documents": "Export selected documents",
      "export-error": "An unexpected error happened during exporting",
      "export-success":
        "Document export queued - you will receive a link via email to download the generated export file once it is generated",
      "export-choose-one": "Select at least one document to export",
      "move-to-folder": "Move files",
      "move-to-project": "Move files to project",
      "delete-selected-files": "Delete selected file(s)",
      "main-folder": "Main folder",
      "available-folders": "Available folders",
      "search-folders": "Search within folders",
      "move-selected-documents": "Move selected documents",
      reports: {
        date: "Date",
        "type-and-file": "Type & File",
        phase: "Phase",
        format: "Format",
        created: "Created",
        back: "Back",
        "show-images": "Show images",
      },
      creator_missing: "Missing document creator: Document delete request id: {{id}}, document id: {{docId}}",
    },
    toasts: {
      timeout: {
        "warning-message": "Your session will expire in 5 minutes.",
        "session-timeout": "Session has timed out",
        "timeout-message": "Please log in again",
        "button-continue": "Continue session",
        "button-logout": "Log out",
        when: "just now",
      },
    },
    modals: {
      relogin: {
        "extend-session": "Extend session",
        "button-extend-session": "Extend session",
        "button-cancel": "Cancel",
      },
    },
    notifications: {
      notifications: "Notifications",
      "newest-notifications": "Newest notifications",
      title: "Title",
      body: "Body",
      attachment: "Attachment",
      "created-by": "Created by:",
      "edited-by": "Edited by:",
      "add-notification": "Add new notification",
      "edit-notification": "Edit notification",
      "button-cancel": "Cancel",
      "button-save": "Save",
      "is-published": "Published",
      yes: "Yes",
      no: "No",
      publish: "Publish",
      "select-file": "Add file",
      "download-file": "Download file",
      "read-more": "Read more",
      "created-at": "Created at: ",
      "updated-at": "Updated at: ",
      "show-all-notifications": "Show all notifications",
    },
    defaultfolders: {
      defaultfolders: "Default folders",
    },
    projectstatuses: {
      projectstatuses: "Project statuses",
    },
    mobilelinks: {
      "page-title": "App links",
      title: "Title",
      link: "Link",
      order: "Order of link",
      "edit-mobilelink": "Edit app link",
      "create-mobilelink": "Create new app link",
    },
    import: {
      title: "Import data from csv",
    },
  },
  errors: {
    "unexpected-login-failure": "An unexpected error happened during login, please retry!",
    "code-500": "Error - Server error, code 500.",
    "code--3": "Error - Unexpected error.",
    "code--2": "Error - Invalid request - code 400.",
    "code--1": "Error - Invalid request (422) - please check inputs.",
    "code-1": "Error - Unauthorized - access denied.",
    "code-2": "Error - Query error.",
    "code-3": "Error - Resource not found.",
    "code-4": "Error - Unauthorized operation.",
    "code-5": "Error - Unauthorized.",
    "code-6": "Error - Plese check inputs.",
    "code-7": "Error - Exception during request.",
    "code-8": "Error - Plese check input.",
    "code-9": "Error - Empty result.",
    "code-10": "Error - Invalid query.",
    "code-11": "Error - Unexpected error - invalid materialform selection detected.",
    "code-12": "Error - Unexpected error - invalid materialform option detected.",
    "code-13": "Error - Unexpected error - invalid materialform.",
    "code-14": "Error - Cannot perform action, would cause conflict.",
    "code-15": "Error - No materialforms are available for use.",
    "code-16": "Error - No apartment templates are available for use.",
    "code-17": "Error - No project available for use.",
    "code-18": "Error - Invalid materialform status - selection mismatch.",
    "code-19": "Error - Materialform contains no selectable options.",
    "code-20": "Error - Deadlines provided, but apartment property not found.",
    "code-21": "Error - Invalid materialform status - default option selection error.",
    "code-22": "Error - unable to save terms agreement.",
    "code-23": "Error - Unable to load terms agreement.",
    "code-24": "Error - File type not supported (supported file types: jpg, jpeg, png, gif).",
    "code-25": "Error - Maximum file count for upload exceeded.",
    "code-26": "Error - Choose at least one file to move it to another folder.",
    "code-27": "Error - This document type cannot be deleted, since it is still in use.",
    "code-28": "Error - Delete defaultfolder descendents first.",
    "code-29": "Error - Project code already in use, please choose another code",
    "code-30": "Error - Failed to fetch file",
    "code-31": "Error - Failed to store notification",
    "code-32": "Error - Failed to store notification attachment",
    "code-33": "Error - Updating the notification failed",
    "code-34": "Error - Start date should be before end date",
    "code-35": "Error - Please fill all the required fields",
    "code-36": "Error - Default project subtype cannot be deleted",
    "code-37": "Error - Please give an integer between 1 and 100",
    "code-38": "Error - Completion percentage must be a number",
    "code-39": "Error - Search query must be present",
    "code-40": "Error - Statuscode already in use",
    "code-41": "Error - Status can't be deleted, it is in use",
    "code-42": "Error - Status can't be deleted, is has children",
    "code-43": "Error - Status can't be deleted, is is a default status",
    "code-44": "Error - Status not found",
    "code-1000":
      "Error - Unable to connect to Avainia core, please retry again later or contact your Avainia contact person for support.",
    "code-1001":
      "Error - Unable to connect to Avainia core, please retry again later or contact your Avainia contact person for support!",
  },
  calendar: {
    week: "Week",
    months: {
      january: "January",
      february: "February",
      march: "March",
      april: "April",
      may: "May",
      june: "June",
      july: "July",
      august: "August",
      september: "September",
      october: "October",
      november: "November",
      december: "December",
    },
    shortMonths: {
      jan: "Jan",
      feb: "Feb",
      mar: "Mar",
      apr: "Apr",
      may: "May",
      jun: "Jun",
      jul: "Jul",
      aug: "Aug",
      sep: "Sep",
      oct: "Oct",
      nov: "Nov",
      dec: "Dec",
    },
    shortWeekdays: {
      su: "su",
      mo: "mo",
      tu: "tu",
      we: "we",
      th: "th",
      fr: "fr",
      sa: "sa",
    },
    weekdays: {
      sunday: "Sunday",
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
    },
  },
};

export const fi = {
  usercategories: {
    heading: "Käyttäjätyypit",
    usercategory: "Käyttäjätyyppi",
    default: "Oletus",
    name: "Nimi",
    "confirm-delete":
      "Oletko varma, että haluat poistaa käyttäjätyypin? Käyttäjät tässä tyypissä siirretään oletustyyppiin.",
    "button-create-new": "Luo uusi käyttäjätyyppi",
  },
  tagInput: {
    "editing-keyword": "Muokataan kenttäarvoa",
    "update-keyword": "Päivitä kenttäarvo",
    "delete-keyword": "Poista kenttäarvo",
    disabled: "Poistettu käytöstä",
  },
  fields: {
    info: "Lisätiedot",
    phase: "Vaihe",
    subject: "Aihe",
    creation_date: "Päivämäärä",
    position: "Positio",
    gps_coordinates: "GPS-koordinaatit",
    gps_accuracy: "Koordinaattien tarkkuus",
    state: "Tila",
  },
  documentType: {
    manageTitle: "Hallitse dokumenttityyppejä ja kenttiä",
    defaultDocumentType: "Kuva",
    keywords: "Kenttäarvot",
    text: "Teksti",
    date: "Päivämäärä",
    "create-document-type": "Luo dokumenttityyppi",
    name: "Nimi",
    "delete-type": "Poista dokumenttityyppi",
    "confirm-delete": "Oletko varma että haluat poistaa dokumenttityypin?",
    update: "Päivitä",
    "enable-disable-fields": "Ota käyttöön/poista käytöstä",
    "field-name": "Kentän nimi",
    "field-type": "Kentän tyyppi",
    enabled: "Käytössä",
    default: "Kuva",
    true: "Kyllä",
    false: "Ei",
    "update-fields": "Päivitä kentät",
    "load-fields": "Näytä kentät...",
    return: "Palaa takaisin",
    "manage-fields": "Hallitse kenttiä",
    "select-type": "Valitse tyyppi",
    actions: "Toiminnot",
    "manage-document-types": "Dokumenttityyppien hallinta",
    view: "Näytä",
    create: "Luo",
    "enter-keyword": "Syötä kenttäarvo/avainsana ja paina enter",
    active: "Aktiivinen",
    "document-type": "Dokumenttityyppi",
    "show-in-documents": "Näytä dokumenttilistauksessa",
  },
  constants: {
    companyTypes: {
      subcontractor: "Aliurakoitsija",
      client: "Asiakas",
      owner: "Omistaja",
    },
    userTypes: {
      Super: "Superadmin",
      employee: "Työntekijä",
      manager: "Esimies",
      editor: "Muokkaaja",
      client: "Asiakas",
      subcontractor: "Aliurakoitsija",
      owner: "Omistaja",
    },
  },
  permissions: {
    permissions: "Käyttöoikeudet",
    "p:defaultfolder_manage": "Oletuskansiot - hallinta",
    "p:projects_manage": "Projektit - hallinta",
    "p:document_edit": "Dokumentit - muokkaus",
    "p:documents_delete": "Dokumentit - poisto",
    "p:users_manage": "Käyttäjät - hallinta",
    "p:companies_manage": "Yritykset - hallinta",
    "p:roles_manage": "Roolit - hallinta",
    "p:import": "Import-toiminto",
    "p:document-delete-requests_manage": "Dokumenttien poistopyynnöt - hallinta",
    "p:keywords_manage": "Avainsanat - hallinta",
    "p:condominiums_manage": "Taloyhtiöt - hallinta",
    "p:products_manage": "Tuotteet - hallinta",
    "p:permissions_manage": "Käyttöoikeudet - hallinta",
    "p:schedules_view": {
      full: "Aikataulut - näytä kokonaisuudessaan",
    },
    "p:notification_access": "Ilmoitukset - hallinta",
    "p:notification_create": "Ilmoitukset - luonti",
    "p:notification_edit": "Ilmoitukset - muokkaus",
    "p:notification_delete": "Ilmoitukset - poisto",
    contentpermission: {
      types: {
        "App\\Project": "Projekti",
        "App\\Folder": "Kansio",
      },
    },
  },
  general: {
    appname: "Avainia",
    deleted: "Poistettu",
    roles: "Roolit",
    document: "Dokumentti",
    user: "Käyttäjä",
    inactiveusers: "Inaktiiviset käyttäjät",
    users: "Käyttäjät",
    id: "ID",
    email: "Sähköposti",
    "new-password": "Uusi salasana",
    loading: "Lataa",
    errormessage: "Toiminnon aikana tapahtui virhe. Virhetieto: {{error}}.",
    password: "Salasana",
    password_confirmation: "Salasana toiseen kertaan",
    configure: "Konfiguroi",
    "table-actions": "Toiminnot",
    companies: "Yritykset",
    apartment: "Asunto",
    "modal-close": "Sulje",
    accept: "Hyväksy",
    reject: "Hylkää",
    cancel: "Peruuta",
    save: "Tallenna",
    return: "Palaa",
    "modal-form-save": "Tallenna",
    areasqm: "Pinta-ala (m²)",
    price: "Hinta",
    "total-price": "Kokonaishinta",
    basicdata: "Perustiedot",
    status: "Status",
    inactive: "Deaktivoitu",
    active: "Aktiivinen",
    address: "Osoite",
    "no-results": "Ei tuloksia",
    "no-folders": "Ei kansioita",
    edit: "Muokkaa",
    "privacy-policy": "Rekisteriseloste",
    terms: "Käyttöehdot",
    "choose-photo": "Valitse kuva",
    "choose-context-photo": "Valitse kontekstikuva",
    "remove-photo": "Poista kuva",
    create: "Luo",
    "save-changes": "Tallenna muutokset",
    delete: "Poista",
    default: "Oletus",
    "avainiatable-no-data-text": "Ei dataa",
    select: "Valitse",
    today: "Tänään",
    "password-reset-match-error": "Salasanat eivät vastaa toisiaan",
    "password-reset-invalid": "Virhe salasanan vaihtamisessa. Hae uusi linkki, ja yritä uudelleen.",
    required: "* Pakollinen kenttä",
    "button-cancel": "Peruuta",
    "button-save": "Tallenna",
    confirm: "Vahvista",
    "document-amount": "Dokumentteja",
    "document-created": "Viimeisin dokumentti",
  },
  defaultfolders: {
    name: "Nimi",
    parent: "Ylätaso",
    explanation:
      "Projektien oletuskansioilla on oma itsenäinen ja yhteinen lupajärjestelmä ja niiden lupia ei voi hallita projektikohtaisesti.",
    "create-new-child": "Luo uusi oletuskansio tämän kansion alikansioksi",
    "button-create-new": "Luo uusi päätason oletuskansio",
    "create-defaultfolder": "Luo uusi oletuskansio",
    "manage-defaultfolder": "Hallitse oletuskansiota",
    "confirm-delete": "Haluatko varmasti poistaa tämän oletuskansion?",
    "project-subtypes-and-default-folders": "Projektityypit ja oletuskansiot",
    "edit-defaultfolder-name": "Muokkaa oletuskansion nimeä",
    "edit-defaultfolder-permissions": "Muokkaa oletuskansion lupia",
  },
  projectSubtypes: {
    "project-subtypes-select": "Valitse projektityyppi",
    "project-subtypes-create": "Luo projektityyppi",
    "project-subtypes-name": "Projektityypin nimi",
    "project-subtype": "Projektityyppi",
    "project-subtypes-edit": "Muokkaa projektityyppiä",
  },
  projectStatuses: {
    "project-statuses": "Projektin statukset",
    "project-status-tree": "Projektin statuslista",
    "edit-status": "Muokkaa statusta",
    active: "Aktiivinen",
    "click-to-deactivate": "Klikkaa deaktivoidaksesti tämä status",
    "click-to-activate": "Klikkaa aktivoidaksesti tämä status",
    "create-new-status": "Luo uusi status",
    "status-slug": "Statuksen koodi",
    "status-name": "Statuksen nimi",
    "status-parent": "Ylästatus",
    "status-active": "Onko status aktiivinen",
    "status-active-in-projects": "Status on käytössä seuraavilla projekteilla:",
    "confirm-delete": "Haluatko varmasti poistaa statuksen?",
    "select-by-status": "Filteröi statuksella",
  },
  folders: {
    defaultfolders: "Projektin oletuskansiot",
    enabled: "Kansiot päällä",
    disabled: "Kansiot pois päältä",
    "confirm-delete":
      "Haluatko varmasti poistaa kansion pysyvästi? Vain täysin tyhjän kansion voi poistaa. Oletuskansioita ei voi poistaa.",
    folder: "Kansio",
    folders: "Kansiot",
    "files-in-subfolders": "Tiedostoja alikansioissa",
    "warning-visibility":
      "Huom! Tiedostot, jotka eivät ole alikansiossa, ovat aina näkyvillä kaikille käyttäjille, joilla on pääsy projektiin.",
    "please-select-company": "Valitse yritys hallitaksesi kyseisen yrityksen kansioiden näkyvyyttä.",
    "click-to-grant-permission": "Klikkaa antaaksesi käyttöoikeus",
    "click-to-revoke-permission": "Klikkaa poistaaksei käyttöoikeus",
    filecount: "Tiedostoja",
    mostRecentDocument: "Viimeisin dokumentti",
    subfolders: "Alikansioita",
    parentFolder: "Yläkansio",
    name: "Nimi",
  },
  user: {
    roles: "Roolit",
    role: "Rooli",
  },
  documents: {
    deleteRequests: "Dokumenttien poistopyynnöt",
    deleteRequest: "Dokumenttien poistopyynntö",
    deleteRequestRequestedBy: "Pyytäjä",
    confirmAcceptDeleteRequest: "Haluatko varmasti hyväksyä poistopyynnön?",
    confirmRejectDeleteRequest: "Haluatko varmasti hylätä poistopyynnön?",
    deleteRequestDeleteSelected: "Hyväksy valitut poistopyynnöt",
  },
  search: {
    "no-results": "Ei hakutuloksia",
    "search-result": "Hakutulos",
    "search-results": "Hakutulokset",
    "search-results-for": "Hakutulokset kohteelle",
    documents: "Dokumentit",
    project: "Projekti",
    projects: "Projektit",
    "download-file": "Lataa tiedosto",
    "move-to-project": "Siirry projektiin",
    close: "Sulje",
    page: "Sivu",
    search: "Hae",
    "advanced-search": "Tarkennettu haku",
  },
  views: {
    summaryviews: {
      addsummaryview: "Luo uusi yhteenvetosivu",
      confirmdelete: "Oletko varma, että haluat poistaa tämän yhteenvetosivun?",
      subcontractor: "Aliurakoitsija",
      changestatus: "Vaihda tila",
    },
    progressnumbers: {
      progressnumbers: "Työn edistyminen",
      usedpetrol: "Käytetty polttoaine määrä",
      cabletrenchdig: "Kaapeliojan kaivu",
      laidcable: "Kaapelin asennus ojaan, putkeen tai aurauksen yhteydessä",
      cablecoverinstalled: "Suojaputken tai kourun asennus",
      eleccabinetinstalled: "Jakokaapin tai haaroituskaapin asentaminen",
      transinstalled: "Puisto- tai maaseutumuuntamon perustaminen",
      wedtileinstalled: "Haruksen rakentaminen haruslaatalla tai kalliosilmuksella",
      polesinstalled: "Puupylvään pystytys",
      directdrilling: "Suuntaporaus tai alitus tunkkaamalla",
      asphaltinstalled: "Asfaltointi tai nurmetus",
      kpl: "kappaletta",
      m: "metriä",
      l: "litraa",
      m2: "neliömetriä",
      addreport: "Syötä raportti",
      progressreports: "Etenemisraportit",
      timeperiod: "Suodata päivämäärän mukaan",
      amount: "Määrä",
      tasks: "Tehtävät",
      addtasks: "Lisää tehtävä",
      confirmreportdelete: "Oletko varma, että haluat poistaa raportin?",
    },
    documents: {
      documents: "Dokumentit",
      type: "Tyyppi",
      "select-type": "Valitse dokumenttityyppi",
      next: "Seuraava",
      "replace-file": "Vaihda tiedosto",
      "choose-new-file": "Valitse uusi tiedosto",
      "current-file": "Nykyinen tiedosto",
      "new-file": "Uusi tiedosto",
      "no-preview-text": "Esikatselu ei käytössä",
      "group-name": "Ryhmän nimi",
    },
    sidebar: {
      "language-selector": "Kielivalinta",
      "link-admin": "Oletuskansiot",
      "link-projects": "Projektit",
      "link-projects-admin": "Projektinhallinta",
      "link-projects-inactive": "Projekti Arkisto",
      "link-apartments": "Asunnot",
      "link-condominiums": "Taloyhtiöt",
      "link-privacy": "Rekisteriseloste",
      "link-translations": "Käännökset",
      "link-logout": "Kirjaudu ulos",
      "link-products": "Tuotteet",
      "link-permissions": "Käyttöoikeudet",
      "link-companies": "Yritykset",
      "link-company-management": "Yrityksen hallinta",
      "user-photo-alt": "Käyttäjä",
      "document-types": "Dokumenttityypit",
      notifications: "Ilmoitukset",
      mobilelinks: "Sovelluslinkit",
      userphotomodal: {
        title: "Vaihda käyttäjäkuva",
        button: "Vaihda",
        "photo-preview": "Esikatselu",
      },
      sharedsummaries: "Jaetut yhteenvetosivut",
    },
    materialforms: {
      materialforms: "Materiaalinvalintalomakkeet",
      materialform: "Materiaalinvalintalomake",
      "link-matform-to-apt-template": "Aktivoi materiaalinvalintalomake asuontopohjaan",
      "materialform-name": "Nimi",
      "deadlines-for": "Materiaalinvalintalomakkeen aikarajat kohteelle ",
      deadlines: "Aikarajat",
      deadline: "Aikaraja",
      "button-add-link": "Aktivoi materiaalinvalintalomake asuntopohjaan",
      "button-remove-link": "Deaktivoi",
      "button-add-option": "Lisää vaihtoehto",
      "button-add-selection": "Lisää valinta",
      "materialform-add-new-option": "Lisää uusi vaihtoehto",
      "materialform-add-new-group": "Uusi ryhmä",
      "materialform-add-new-select": "Uusi valinta",
      "materialform-group-name": "Ryhmän nimi",
      "materialform-group": "Ryhmät",
      "materialform-selection": "Valinnat",
      "materialform-select-choose-productcategory": "Valitse tuotekategoria",
      "materialform-option": "Vaihtoehto",
      "materialform-options": "Vaihtoehdot",
      "create-new-materialform": "Luo uusi materiaalinvalintalomake",
      "default-option": "Urakanmukainen",
      "button-create": "Luo",
      "button-cancel": "Peruuta",
      "button-save": "Tallenna",
      "button-add-group": "Lisää ryhmä",
      "button-quote-create": "Luo tarjous",
      "button-quote-show": "Näytä tarjous",
      "button-create-quote": "Luo tarjousluonnos",
      "button-save-quote": "Tallenna tarjous",
      "button-publish-quote": "Julkaise tarjous",
      "button-view-quote": "Näytä materiaalinvalintalomakkeen tarjous",
      "button-approve-quote": "Hyväksy tarjous",
      "button-show-product-report": "Näytä valintaraportti",
      "selections-done": "Valinnat tehty",
      quotes: "Tarjoukset",
      "quotes-heading": "Materiaalinvalintalomakkeiden tarjoukset",
      "quote-name": "Nimi",
      "quote-status-created": "Status: luotu",
      "quote-status-unsaved": "Status: tallentamaton",
      "quote-status-sent": "Status: lähetetty",
      "quote-status-approved": "Status: hyväksytty",
      "edit-title": "Materialform editor",
      "confirm-delete": "Oletko varma, että haluat suorittaa poiston?",
      "link-matform-to-template": "Aktivoi materiaalinvalintalomake asuntopohjaan",
      "apartment-materialform-quote": "Asunnon materiaalinvalintalomakkeen tarjous",
      "confirm-link": "Haluatko varmasti aktivoida valitun materiaalinvalintalomakkeen asuntopohjalle?",
      "confirm-unlink": "Haluatko varmasti deaktivoida valitun materiaalinvalintalomakkeen asuntopohjalta?",
      "confirm-deadline-delete": "Haluatko varmasti poistaa valitun aikarajan?",
    },
    contacts: {
      contacts: "Yhteyshenkilöt",
      "create-contact": "Luo uusi yhteyshenkilö",
      "button-create": "Luo",
      "button-cancel": "Peruuta",
      "button-save": "Tallenna",
      "create-contact-first_name": "Etunimi",
      "create-contact-last_name": "Sukunimi",
      "create-contact-title": "Titteli",
      "create-contact-phone": "Puhelin",
      "edit-contact": "Muokkaa yhteyshenkilöä",
      "confirm-contact-delete": "Haluatko varmasti poistaa tämän yhteyshenkilön?",
    },
    schedules: {
      schedules: "Aikataulut",
      "button-create": "Luo",
      "button-cancel": "Peruuta",
      "button-save": "Tallenna",
      "edit-schedule": "Muokkaa aikataulua",
      "create-schedule": "Luo uusi aikataulu",
      "create-schedule-description": "Kuvaus",
      "create-schedule-start_at": "Alkaa",
      "create-schedule-end_at": "Päättyy",
      "create-schedule-estimate_start_at": "Arvioitu alkamisaika",
      "create-schedule-estimate_end_at": "Arvioitu päättymisaika",
      "create-schedule-parent_id": "Ylätason tunniste",
      "create-schedule-completion": "Valmiusaste",
      schedule: "Aikataulu",
      name: "Nimi",
      start: "Aloitus",
      end: "Lopetus",
      "estimate-start": "Arvioitu aloitusaika",
      "estimate-end": "Arvioitu lopetusaika",
      completed: "Valmiusaste",
    },
    products: {
      productcategories: "Tuotekategoriat",
      productcategory: "Tuotekategoria",
      products: "Tuotteet",
      "products-for": "Kategorian tuotteet",
      "create-new-product": "Luo uusi tuote",
      "create-new-product-category": "Luo uusi tuotekategoria",
      name: "Nimi",
      manufacturer: "Valmistaja",
      code: "Tuotekoodi",
      description: "Kuvaus",
      price: "Hinta",
      measurements: "Mitat",
      external_link: "Ulkoinen linkki",
      "button-create": "Luo",
      "button-save": "Tallenna",
      "edit-product-category": "Muokkaa tuotekategoriaa",
      "product-category-label": "Kategorian nimi",
      "edit-product": "Muokkaa tuotetta",
      "productname-label": "Nimi",
      "productmanufacturer-label": "Valmistaja",
      "productcode-label": "Koodi",
      "productmeasurements-label": "Mitat",
      "productdescription-label": "Kuvaus",
      "productexternallink-label": "Ulkoinen linkki",
      "productprice-label": "Hinta",
      "product-photo-label": "Kuva",
      "product-context-photo-label": "Tilannekuva",
    },
    projects: {
      "confirm-delete": "Haluatko varmasti poistaa projektin tietoineen?",
      projects: "Projektit",
      "projects-archive": "Projekti Arkisto",
      project: "Projekti",
      condominiumproject: "Taloyhtiöprojekti",
      "condominiumproject-needs-project": "Taloyhtiöprojekti täytyy yhdistää taloyhtiöön.",
      "project-code": "Tunniste",
      "project-name": "Nimi",
      type: "Tyyppi",
      condominium: "Taloyhtiö",
      infrastructure: "Infra",
      marine: "Merellinen",
      "create-new-project": "Luo uusi projekti",
      "warn-select-option-first": "Valitse taloyhtiö",
      "button-create": "Luo uusi projekti",
      "search-projects": "Hae projekteista",
      "edit-project": "Muokkaa projektia",
      status: "Status",
      "configure-view": "Mukauta näkymää",
      "project-coordinates": "Projektin koordinaatit",
      loading: "Ladataan projekteja",
      "csv-import": "Tuo CSV-tiedostosta",
      table: {
        "show-id": "Näytä ID",
        "show-code": "Näytä tunniste",
        "show-name": "Näytä nimi",
        "show-status": "Näytä status",
        "show-companies": "Näytä yritykset",
        "show-type": "Näytä tyyppi",
        "show-actions": "Näytä toiminnot",
        "show-inactive": "Näytä inaktiiviset projektit",
      },
      types: {
        condominium: "Taloyhtiöprojekti",
        infrastructure: "Infraprojekti",
      },
      companies: {
        /* TODO: DELETE!! */ companies: "Yritykset",
        name: "Nimi",
        code: "Tunniste",
        type: "Tyyppi",
        search: "Hae yritystä",
        "add-company": "Lisää yritys",
        "attach-company": "Liitä yritys",
        "attached-company": "Liitetty yritys",
        "add-user": "Lisää käyttäjä",
        "edit-company": "Muokkaa yritystä",
        "edit-user": "Muokkaa käyttäjää",
        "company-users": "Yrityksen käyttäjät",
        "company-info": "Yrityksen tiedot",
        phone: "Puhelinnumero",
      },
      "status-disabled": "Inaktiivinen",
      "project-statuses": "Projektien statukset",
      "project-status-changed": "Projektin status vaihdettu",
      statuses: {
        active: "Aktiivinen",
        inactive: "Inaktiivinen",
        before: "Suunnittelu",
        during: "Aloitettu",
        after: "Valmis",
        imported: "Tuotu",
        archived: "Arkistoitu",
      },
    },
    login: {
      "please-enter-new-password": "Aseta uusi salasana",
      "enter-email-for-reset": "Syötä sähköpostiosoitteesi, sinulle lähetetään linkki salasanan nollaamiseen.",
      "forgot-password": "Sähköposti tai salasana unohtunut?",
      "click-here": "Klikkaa tästä",
      "login-button": "Kirjaudu sisään",
      "invalid-credentials": "Tarkista sähköposti ja salasana",
      "privacy-policy-1": "Lue",
      "privacy-policy-2": "rekisteriseloste",
    },
    passwordset: {
      title: "Aseta salasana",
      "message-success": "Tallennus onnistui, voit nyt kirjautua käyttäen antamaasi salasanaa.",
      "message-check-input": "Tarkista syötteet",
      "message-mismatched-input": "Salasanat eivät täsmää",
      "button-save": "Tallenna",
    },
    condominiums: {
      condominiums: "Taloyhtiöt",
      condominium: "Taloyhtiö",
      stairwell: "Rappu",
      "apartment-number": "Huoneiston numero",
      apartmenttemplates: "Asuntopohja",
      apartmenttemplate: "Asuntopohja",
      "edit-apartmenttemplate": "Muokkaa asuntopohjaa",
      "apartmenttemplate-name": "Asuntopohjan nimi",
      "add-new-apartmenttemplate": "Uusi asuntopohja",
      "button-create-apt-template": "Luo uusi asuntopohja",
      "button-delete": "Poista",
      name: "Nimi",
      street_address: "Lähiosoite",
      postcode: "Postinumero",
      post_office: "Postitoimipaikka",
      "button-create-new": "Luo uusi taloyhtiö",
      "button-edit": "Muokkaa taloyhtiötä",
      "tab-basedata": "Perustiedot",
      "tab-apartments": "Huoneistot",
      "confirm-delete": "Oletko varma, että haluat poistaa taloyhtiön?",
      apartments: {
        apartment: "Huoneisto",
        "button-create": "Luo",
        stairwell: "Rappu",
        apartment_number: "Huoneiston numero",
        apartment_template_id: "Huoneistopohja",
      },
      users: {
        title: "Käyttäjät",
        "edit-user": "Muokkaa käyttäjää",
        "add-user": "Lisää käyttäjä",
        name: "Nimi",
        code: "Tunniste",
        phone: "Puhelinnumero",
        type: "Tyyppi",
        add: "Lisää käyttäjä",
        search: "Hae",
        specifier: "Tarkenne",
      },
    },
    companies: {
      companies: "Yritykset",
      name: "Nimi",
      code: "Tunniste",
      type: "Tyyppi",
      search: "Hae yritystä",
      "add-company": "Lisää yritys",
      "attach-company": "Liitä yritys",
      "add-user": "Lisää käyttäjä",
      "edit-company": "Muokkaa yritystä",
      "manage-company": "Hallitse yritystä",
      "edit-user": "Muokkaa käyttäjää",
      "edit-self-warning": "Huomautus: Muokkaat omaa käyttäjätiliäsi.",
      "company-users": "Yrityksen käyttäjät",
      "company-info": "Yrityksen tiedot",
      phone: "Puhelinnumero",
      "user-add": "Lisää uusi käyttäjä",
      "reset-password": "Palauta salasana",
      "reset-password-email-sent": "Käyttäjän sähköpostiin lähetettiin salasanan palautuslinkki.",
      "reset-password-confirm": "Oletko varma, että haluat lähettää käyttäjälle salasanan palautuslinkin?",
      "new-password": "Uusi salasana",
      "set-new-password": "Aseta Uusi salasana",
      "new-password-success": "Salasana vaihdettu onnistuneesti!",
      "new-password-empty-warning": "Uusi salasana ei voi olla tyhjä",
      "csv-import": "Tuo CSV tiedostosta",
    },
    permissions: {
      "confirm-permission": "Haluatko varmasti antaa luvan {{permission}} roolille {{role}}?",
      "confirm-permission-revoke": "Haluatko varmasti poistaa luvan {{permission}} roolilta {{role}}?",
      "tab-permissions": "Käyttöoikeudet",
      "tab-createpermission": "Luo uusi käyttöoikeus",
      permissions: "Käyttöoikeudet",
      "table-target": "Kohde",
      "table-specifier": "Tarkenne",
      "table-user-group": "Käyttäjäryhmä",
      "table-permission": "Käyttöoikeus",
      "button-new-permission": "Uusi käyttöoikeus",
      "create-new-permission": "Luo uusi käyttöoikeus",
      "create-permission-target": "Kohde",
      "create-permission-specifier": "Tarkenne",
      "create-permission-usergroup": "Käyttäjäryhmä",
      "create-permission-permission": "Käyttöoikeus",
      "edit-permission": "Muokkaa käytöoikeutta",
      "new-usergroup-label": "Tai",
      "create-new-usergroup": "Luo uusi käyttäjäryhmä",
      "usergroup-exists-error": "Käyttäjäryhmä on jo olemassa",
      "input-empty-error": "Käyttäjäryhmä pitää olla valittuna",
      "create-permission-error": "Valitse arvo jokaiseen kohtaan",
      search: "Hae",
      "target1-tooltip": "Käyttäjäryhmän kohde",
      "target2-tooltip": "Luvan kohde",
    },
    infraproject: {
      addedByCompany: "Yritys",
      filename: "Tiedoston nimi",
      "add-document": "Lisää dokumentti",
      "select-files": "Lisää tiedostoja",
      "max-files": "Tiedostojen maksimimäärä: ",
      "add-folder": "Lisää kansio",
      displaying: "Näytetään",
      "configure-view": "Mukauta näkymää",
      filter: "Hae hakusanalla...",
      "filter-documents": "Suodata dokumentteja",
      "filter-clear": "Poista suodatukset",
      info: "Lisätiedot",
      nbr: "Nro",
      company: "Yritys",
      download: "Lataa",
      delete: "Poista",
      edit: "Muokkaa",
      location: "Koordinaatit",
      "coordinate-accuracy": "Koordinaattien tarkkuus",
      position: "Positio",
      phase: "Vaihe",
      phases: "Vaiheet",
      date: "Päivämäärä",
      settings: "Asetukset",
      subject: "Aihe",
      subjects: "Aiheet",
      state: "Tila",
      states: "Tilat",
      created: "Luotu",
      "toggle-mapview": "Vaihda karttanäkymään",
      "show-map": "Näytä kartta",
      "hide-map": "Piilota kartta",
      "expand-map": "Laajenna kartta",
      "collapse-map": "Pienennä kartta",
      "full-screen-map": "Koko näytön näkymä",
      "collapse-full-screen-map": "Poistu koko näytön näkymästä",
      "no-coloring": "Ei väritystä",
      "color-by-company": "Väritys yrityksittäin",
      "color-by-subject": "Väritys aiheittain",
      inactive: "Epäaktiivinen",
      "add-document-failure": "Kuvan lisäys epäonnistui",
      "add-folder-failure": "Kuvan lisäys epäonnistui",
      "choose-a-phase": "Valitse vaihe",
      "choose-a-subject": "Valitse aihe",
      "choose-a-file": "Valitse tiedosto",
      "mode-table": "TAULUKKO",
      "mode-icons": "IKONIT",
      "mode-reports": "RAPORTIT",
      "sort-by-date": "Lajittele päivämäärän mukaan",
      "sort-by-type": "Lajittele tyypin mukaan",
      "show-company": "Näytä yritys",
      "show-name": "Näytä nimi",
      "show-info": "Näytä lisätiedot",
      "show-position": "Näytä positio",
      "show-phase": "Näytä vaihe",
      "show-subject": "Näytä aihe",
      "show-state": "Näytä tila",
      "confirm-cancel-changes": "Haluatko peruuttaa tekemäsi muutokset?",
      "confirm-document-delete": "Haluatko varmasti poistaa valitun dokumentin?",
      "confirm-documents-delete": "Haluatko varmasti poistaa valitut dokumentit?",
      "error-saving-check-phase": "Virhe tallennuksessa, tarkista vaihe",
      "error-saving-check-subject": "Virhe tallennuksessa, tarkista aihe",
      "save-success": "Tallennus onnistui",
      "delete-success": "Poisto onnistui",
      "button-add-document": "Lisää dokumentti",
      "button-add-folder": "Lisää kansio",
      "button-show-details": "Näytä tiedot",
      "button-download": "Lataa",
      "button-edit": "Muokkaa",
      "button-edit-coordinates": "Muokkaa koordinaatteja",
      "export-selected-documents": "Vie valitut dokumentit",
      "export-error": "Viennin tallentamisessa tapahtui ennakoimaton virhe",
      "export-success": "Vienti asetettu käsittelyjonoon - saat linkin generoituun vientitiedostoon sähköpostilla.",
      "export-choose-one": "Valitse ainakin yksi dokumentti vietäväksi",
      "move-to-folder": "Siirrä tiedostoja",
      "move-to-project": "Siirrä projektiin",
      "delete-selected-files": "Poista tiedosto(t)",
      "main-folder": "Pääkansio",
      "available-folders": "Valittavat kansiot",
      "search-folders": "Etsi kansio",
      "move-selected-documents": "Siirrä valitut dokumentit",
      reports: {
        date: "Päivämäärä",
        "type-and-file": "Tyyppi & Tiedosto",
        phase: "Vaihe",
        format: "Formaatti",
        created: "Luotu",
        back: "Edellinen",
        "show-images": "Näytä kuvat",
      },
      creator_missing: "Dokumentin tekijä puuttuu: Dokumentin poistopyynnön id: {{id}}, dokumentin id: {{docId}}",
    },
    toasts: {
      timeout: {
        "warning-message": "Sessio päättyy 5 minuutin kuluttua",
        "session-timeout": "Sessio on päättynyt",
        "timeout-message": "Kirjaudu sisään uudelleen",
        "button-continue": "Jatka sessiota",
        "button-logout": "Kirjaudu ulos",
        when: "juuri nyt",
      },
    },
    modals: {
      relogin: {
        "extend-session": "Jatka sessiota",
        "button-extend-session": "Jatka sessiota",
        "button-cancel": "Peruuta",
      },
    },
    notifications: {
      notifications: "Ilmoitukset",
      "newest-notifications": "Uusimmat ilmoitukset",
      title: "Otsikko",
      body: "Viesti",
      attachment: "Liitetiedosto",
      "created-by": "Tekijä",
      "edited-by": "Muokkaaja",
      "add-notification": "Lisää ilmoitus",
      "edit-notification": "Muokkaa ilmoitusta",
      "button-cancel": "Peruuta",
      "button-save": "Tallenna",
      "is-published": "Julkaistu",
      yes: "Kyllä",
      no: "Ei",
      publish: "Julkaise",
      "select-file": "Lisää tiedosto",
      "download-file": "Lataa tiedosto",
      "read-more": "Lue lisää",
      "created-at": "Luotu",
      "updated-at": "Muokattu",
      "show-all-notifications": "Näytä kaikki ilmoitukset",
    },
    defaultfolders: {
      defaultfolders: "Oletuskansiot",
    },
    projectstatuses: {
      projectstatuses: "Projektien statukset",
    },
    mobilelinks: {
      "page-title": "Sovelluslinkit",
      title: "Otsikko",
      link: "Linkki",
      order: "Linkin järjestys",
      "edit-mobilelink": "Muokkaa sovelluslinkkiä",
      "create-mobilelink": "Luo uusi sovelluslinkki",
    },
    import: {
      title: "Tietojen tuonti CSV:stä",
      "import-projects": "Tuo projektitietoja",
      here: "tästä",
      "import-projects-description": "Tuo projektit csv tiedostossa. Lataa esimerkki pohja",
      "import-users": "Tuo käyttätiedot",
      "import-users-description": "Tuo käyttäjät ja yritykset csv tiedostossa. Lataa esimerkki pohja",
      "upload-csv": "Lataa .csv tiedosto",
      confirm: "Vahvista",
    },
  },
  errors: {
    "unexpected-login-failure": "Ennakoimaton virhe kirjautumisessa, ole hyvä ja yritä uudelleen.",
    "code-500": "Virhe - Palvelinvirhe (500).",
    "code--3": "Virhe - Odottamaton virhe.",
    "code--2": "Virhe - Virheellinen toiminto (400).",
    "code--1": "Virhe - Virheellinen toiminto (422) - tarkista syötteet.",
    "code-1": "Virhe - Lupa toimintoon evätty.",
    "code-2": "Virhe - Kyselyvirhe.",
    "code-3": "Virhe - Resurssia ei löytynyt.",
    "code-4": "Virhe - Ei käyttöoikeutta toimintoon.",
    "code-5": "Virhe - Luvaton toiminto.",
    "code-6": "Virhe - Tarkista syötteet.",
    "code-7": "Virhe - Toiminnon aikana tapahtui virhe.",
    "code-8": "Virhe - Tarkista syöte.",
    "code-9": "Virhe - Tyhjä tulos.",
    "code-10": "Virhe - Virheellinen kysely.",
    "code-11": "Virhe - Odottamaton virhe - virheellinen materiaalivalinnan valinta.",
    "code-12": "Virhe - Odottamaton virhe - virheellinen materiaalivalinnan vaihtoehto.",
    "code-13": "Virhe - Odottamaton virhe - virheellinen materiaalivalintalomake.",
    "code-14": "Virhe - Toimintoa ei voida suorittaa, se aiheuttaisi datavirheen.",
    "code-15": "Virhe - Materiaalivalintalomaketta ei löytnyt.",
    "code-16": "Virhe - Huoneistopohjaa ei löytynyt.",
    "code-17": "Virhe - Ei käyttöön soveltuvaa projektia.",
    "code-18": "Virhe - Virheellinen materiaalinvalintalomakkeen tila - valintavirhe.",
    "code-19": "Virhe - Materiaalinvalintalomakkeella ei valittavia vaihtoehtoja.",
    "code-20": "Virhe - Aikaraja löytyi, mutta asuntoa ei.",
    "code-21": "Virhe - Virheellinen materiaalinvalintalomakkeen tila - virheellinen oletusvaihtoehto.",
    "code-22": "Virhe - Käyttöehtojen hyväksynnän tallennus epäonnistui.",
    "code-23": "Virhe - Käyttöehtojen hyväksynnän lataus epäonnistui.",
    "code-24": "Virhe - Tiedostotyyppiä ei tueta, sallitut: jpg, jpeg, png, gif.",
    "code-25": "Virhe - Tiedostojen latauksen maksimimäärä ylitetty.",
    "code-26": "Virhe - Valitse vähintään yksi siirrettävä tiedosto.",
    "code-27": "Virhe - Dokumenttityyppiä ei voida poistaa, sillä se on vielä käytössä.",
    "code-28": "Virhe - Oletuskansion alikansiot tulee poistaa ensin.",
    "code-29": "Virhe - Projektin tunniste on jo käytössä, ole hyvä ja valitse toinen tunniste",
    "code-30": "Virhe - Tiedoston lataus epäonnistui",
    "code-31": "Virhe - Ilmoituksen tallennus epäonnistui",
    "code-32": "Virhe - Ilmoituksen liitteen tallennus epäonnistui",
    "code-33": "Virhe - Ilmoituksen päivittäminen epäonnistui",
    "code-34": "Virhe - Aloituspäivämäärän tulee olla ennen lopetuspäivämääärää",
    "code-35": "Virhe - Tarkista, että kaikki tiedot on syötetty",
    "code-36": "Virhe - Oletusprojektityyppiä ei voida poistaa",
    "code-37": "Virhe - Anna kokonaisluku väliltä 1-100",
    "code-38": "Virhe - Valmiusasteen on oltava numero",
    "code-39": "Virhe - Hakusana tai -lause on pakollinen",
    "code-40": "Virhe - Statuskoodi on jo käytössä",
    "code-41": "Virhe - Statusta ei voida tuhota koska se on käytössä",
    "code-42": "Virhe - Statusta ei voida poistaa, koska sillä on alastatuksia",
    "code-43": "Virhe - Statusta ei voida poistaa, koska se on oletusstatus",
    "code-44": "Virhe - Statusta ei löytynyt",
    "code-1000":
      "Virhe - Yhteys palvelimelle epäonnistui, yritä uudelleen myöhemmin tai ota yhteyttä Avainia tukihenkilöön.",
    "code-1001":
      "Virhe - Yhteys palvelimelle epäonnistui, yritä uudelleen myöhemmin tai ota yhteyttä Avainia tukihenkilöön!",
    "code-import-error": "Virhe - Odottamaton virhe tietojen tuonnissa, ota yhteyttä Avainia tukihenkilöön",
  },
  calendar: {
    week: "Viikko",
    months: {
      january: "Tammikuu",
      february: "Helmikuu",
      march: "Maaliskuu",
      april: "Huhtikuu",
      may: "Toukokuu",
      june: "Kesäkuu",
      july: "Heinäkuu",
      august: "Elokuu",
      september: "Syyskuu",
      october: "Lokakuu",
      november: "Marraskuu",
      december: "Joulukuu",
    },
    shortMonths: {
      jan: "Tam",
      feb: "Hel",
      mar: "Maa",
      apr: "Huh",
      may: "Tou",
      jun: "Kes",
      jul: "Hei",
      aug: "Elo",
      sep: "Syy",
      oct: "Lok",
      nov: "Mar",
      dec: "Jou",
    },
    shortWeekdays: {
      su: "su",
      mo: "ma",
      tu: "ti",
      we: "ke",
      th: "to",
      fr: "pe",
      sa: "la",
    },
    weekdays: {
      sunday: "Sunnuntai",
      monday: "Maanantai",
      tuesday: "Tiistai",
      wednesday: "Keskiviikko",
      thursday: "Torstai",
      friday: "Perjantai",
      saturday: "Lauantai",
    },
  },
};

const translations = { en, fi };

export default translations;
