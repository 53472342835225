import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { TopbarContext } from "../../../contexts/TopbarContext";
import LocalStorageService from "../../../AvainiaTools/LocalStorageService";
import AvainiaPermissions from "../../../AvainiaTools/AvainiaPermissions";
import AvainiaCore from "avainia-core-api";
import SummaryCompanies from "./SummaryCompanies";
import I18n from "i18n-js";
import { Tab, Badge } from "react-bootstrap";

import moment from "moment";
import Error from "../../multiview/Error/Error.js";
import Loading from "../../multiview/Loading/Loading.js";
import SummaryModalViewGroup from "../../mainviews/Summary/SummaryModalViewGroup.js";
import DocumentTable from "../../mainviews/Projects/Project/DocumentTable.js";
import SummaryMap from "../../mainviews/Summary/SummaryMap.js";
import AvainiaPanel from "../../multiview/Panels/AvainiaPanel.js";
import SummaryTable from "./SummaryTable.js";

import "./SummaryView.scss";
import UtilService from "../../../AvainiaTools/UtilService.js";
import Utils from "../../../AvainiaTools/Utils.js";

const Tabs = {
  base: "base",
  summaryCompanies: "summarycompanies",
};
class SummaryView extends Component {
  static contextType = TopbarContext;

  constructor(props) {
    super(props);

    this.state = {
      view: {
        filter: "",
        filterToDate: "",
        filterFromDate: "",
        activeFilters: { state: [], subcontractor: [] },
        activeImage: null,
        layout: "",
        flatMode: false,
        mapVisibility: true,
        activeGroup: null,
      },
      tab: Tabs.base,
      forceMapview: false,
      folders: [],
      allImages: [],
      visibleImages: [],
      companies: [],
      subjects: [],
      states: [],
      subcontractor: [],
      fields: [],
      documentTypes: [],
      summaryView: [],
      activeMarker: false,
      type: "",
      initialized: false,
      error: false,
      loading: true,
      imagesByGroups: [],
    };
  }

  componentDidMount() {
    const tabs = [<Tab key={Tabs.base} eventKey={Tabs.base} title={I18n.t("general.basicdata")} />];
    const userManagementTabKey = Tabs.summaryCompanies;

    this.context.setTopbar(tabs, userManagementTabKey);

    //INFRAVIEW
    const { project, configuration } = this.props;
    const id = this.props.match.params.id;
    const user = LocalStorageService.getUser();
    const canManage = user && user.hasPermission(AvainiaPermissions.ProjectsManage);
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.oneSummaryViewGet(this.props.match.params.id).then((summaryView) => {
      if (summaryView.error) {
        return this.setState({ error: summaryView.error });
      }
      this.setState({ summaryView: summaryView });
    });
    const promises = [
      api.projectStatusesGet("statuses"),
      Utils.Globals.getGlobals(),
      api.documentTypesGet("documentTypes"),
      api.summaryViewsGetDocuments(
        id,
        null,
        (x) => {
          return x;
        },
        "documents"
      ),
    ];

    Promise.all(promises).then((data) => {
      let error = false;
      let folders = [];
      let documentTypes;
      let documents = [];
      let statuses = [];

      data.forEach((x) => {
        if (error) {
          return;
        }
        if (x.error) {
          error = x.error;
          return;
        }
        if (x.documentTypes) {
          documentTypes = x.documentTypes;
        }
        if (x.statuses) {
          statuses = x.statuses;
        }
        if (Array.isArray(x)) {
          documents = x;
        } else if (Array.isArray(x.documents)) {
          documents = x.documents;
        }
      });

      if (error) {
        return this.setState({ error });
      }


      let companies = [];
      documents.forEach((document) => {
        companies.push(document.owner_company_name);
      });
      companies = [...new Set(companies)];

      const fields = window.Avainia.Globals.Fields;
      const convertedDocs = documents.map((doc, index) => {
        const converted = UtilService.dbDocumentToImage(doc, id, fields, index);
        converted.document_type = documentTypes.find((x) => Number(x.id) === Number(doc.document_type_id));
        return converted;
      });

      const allImages = convertedDocs;
      const stateField = fields.find((f) => f.key === "state");
      const states = stateField.keywords.filter((kw) => !kw.deactivated);

      const { view, initialActiveDocument } = this.getInitialView(states);
      const visibleImages = this.getVisibleImages(view, allImages);
      const imagesByGroups = this.getImagesByGroups(visibleImages);

      // Get "visible subjects" for map markers
      let imageSubjects = [];
      visibleImages.forEach((image) => {
        imageSubjects.push(image.subject);
      });
      imageSubjects = [...new Set(imageSubjects)].sort();
      LocalStorageService.incrementProjectOpeningCount(id);

      const isInfra = true;
      const userManagementTabKey = canManage ? Tabs.companies : false;

      const mapViewToggleEnabled = isInfra && this.props.configuration.hideableMap;

      const phaseField = fields.find((f) => f.key === "phase");
      const allPhases = phaseField.keywords;
      const phases = phaseField.keywords.filter((kw) => !kw.deactivated);

      const subjectField = fields.find((f) => f.key === "subject");
      const subjects = subjectField.keywords.filter((kw) => !kw.deactivated);

      const subcontractor = this.state.summaryView.companies;

      this.setState(
        {
          view,
          project,
          folders,
          allImages,
          visibleImages,
          companies,
          imageSubjects,
          allPhases,
          phases,
          subjects,
          states,
          subcontractor,
          fields,
          statuses,
          documentTypes,
          imagesByGroups,
        },
        () => {
          this.setState({ initialized: true }, () => {
            if (initialActiveDocument) {
              const initialImage = this.getImageData(initialActiveDocument);
              if (initialImage) {
                this.activateImage(initialImage);
              }
            }
          });
          this.context.mapViewHasChangedCallback(this.toggleMapVisibility);
          this.setState({ loading: false });
        }
      );
    });
  }
  componentDidUpdate() {
    if (this.props.location.hash === "#base" && this.context.tab !== Tabs.base) {
      this.context.setTab(Tabs.base);
    }
  }

  //INFRAMAP FUNCTIONS

  getInitialView = (states) => {
    const viewInitString = this.props.match.params.view;
    let initialActiveDocument = false;

    if (viewInitString) {
      try {
        // TODO: sanitize and validate phases etc for outdated or removed
        const o = JSON.parse(decodeURIComponent(viewInitString));
        if (o && typeof o.activeImage === "number") {
          initialActiveDocument = o.activeImage;
        }
        if (typeof o === "number") {
          initialActiveDocument = o;
        }
      } catch (e) {
        console.error(`JSON parse fail ${e.message}`); // TODO: Actual error reporting
      }
    }

    const view = { ...this.state.view };
    view.mapVisibility = this.context.mapViewActive;

    const defaultFilters = [];

    this.props.configuration.summaryviews.summaryviewDefaultStates.map((id) => {
      const defaultFilter = states.find((x) => x.id === id);
      if (!defaultFilter) {
        return;
      }
      defaultFilters.push(defaultFilter.text);
    });

    view.activeFilters = { state: defaultFilters, subcontractor: [] };

    this.updateUrl(view, this.props.match.params.id);

    return { view, initialActiveDocument };
  };

  toggleMapVisibility = (mapView) => {
    const view = { ...this.state.view };
    view.mapVisibility = mapView;
    this.setState({ view });
    this.updateUrl(view);
  };

  toggleLayout = (mode) => {
    const view = { ...this.state.view };
    view.layout = mode;
    this.setState({ view });
    this.updateUrl(view);
  };

  updateUrl = (newView, id) => {
    const summaryviewId = id || this.props.match.params.id;
    const urlAppendix = `/summaryview/${summaryviewId}/${encodeURIComponent(JSON.stringify(newView))}`;
    // TODO: This works, but seems suboptimal
    this.props.history.replace(urlAppendix);
  };

  removeFilter = (e) => {
    const { target } = e;
    let newFilter = [];
    let newFilters = { state: [], subcontractor: [] };

    if (target.id === "state-filter") {
      newFilter = this.state.view.activeFilters.state.filter((state) => state !== target.parentNode.innerText);
      newFilters = { state: newFilter, subcontractor: this.state.view.activeFilters.subcontractor };
    } else {
      newFilter = this.state.view.activeFilters.subcontractor.filter(
        (subcontractor) => subcontractor !== target.parentNode.innerText
      );
      newFilters = { state: this.state.view.activeFilters.state, subcontractor: newFilter };
    }

    const view = { ...this.state.view };
    view.activeFilters = newFilters;

    const visibleImages = this.getVisibleImages(view);
    const imagesByGroups = this.getImagesByGroups(visibleImages);

    this.setState({ visibleImages, view, imagesByGroups });
    this.updateUrl(view);
  };

  toggleFilter = (e) => {
    e.stopPropagation();
    let { target } = e;
    let sanityBreaker = 40;

    while (--sanityBreaker) {
      // TODO Refactor?
      if (target.classList.contains("filter-toggle")) {
        break;
      }
      target = target.parentNode;
    }

    if (!sanityBreaker) {
      console.error("SanityBreaker triggered!"); /* TODO: Actual error logging */
    }

    target.classList.toggle("active");

    // Update filters
    // TODO Refactor?
    const activeFilters = target.parentNode.parentNode.parentNode.querySelectorAll(".filter-toggle.active");
    const newFilters = { state: [], subcontractor: [] };

    activeFilters.forEach((filter) => {
      let type = filter.dataset.filtertype;
      if (!type) {
        type = filter.children[0].dataset.filtertype;
      }
      newFilters[type].push(filter.innerText.trim());
    });

    const view = { ...this.state.view };
    view.activeFilters = newFilters;

    const visibleImages = this.getVisibleImages(view);
    const imagesByGroups = this.getImagesByGroups(visibleImages);

    this.setState({ visibleImages, view, imagesByGroups });
    this.updateUrl(view);
  };

  resetFilters = () => {
    const newFilters = { state: [], subcontractor: [] };
    const view = { ...this.state.view };

    view.filter = "";
    view.filterFromDate = "";
    view.filterToDate = "";
    view.activeFilters = newFilters;
    view.layout = "";

    const visibleImages = this.getVisibleImages(view);
    const imagesByGroups = this.getImagesByGroups(visibleImages);
    this.setState({ visibleImages, view, imagesByGroups });
    this.updateUrl(view);
  };

  getVisibleImages(view, allImages) {
    const { activeFilters } = view;
    const lowcaseFilter = view.filter ? view.filter.toLowerCase() : null;
    const fromFilter = view.filterFromDate ? moment(view.filterFromDate, "YYYY.MM.DD").startOf("day") : false;
    const toFilter = view.filterToDate ? moment(view.filterToDate, "YYYY.MM.DD").endOf("day") : false;

    const mainSubsectOfImags = allImages || this.state.allImages;

    function match(a) {
      return a ? a.toLowerCase().indexOf(lowcaseFilter) !== -1 : false;
    }

    const visibleImages = mainSubsectOfImags.filter((image) => {
      const stateMatch = activeFilters.state?.length
        ? activeFilters.state.some((state) => image.state === state)
        : true;
      if (!stateMatch) {
        return false;
      }

      const subcontractorMatch = activeFilters.subcontractor?.length
        ? activeFilters.subcontractor.some((subcontractor) => image.summary_companies.includes(subcontractor))
        : true;
      if (!subcontractorMatch) {
        return false;
      }

      const dateMatch = true; /* TODO: Check implementation */
      if (!dateMatch) {
        return false;
      }

      if (image.createdMoment) {
        if (fromFilter && fromFilter.isValid() && fromFilter.diff(image.createdMoment) > 0) {
          return false;
        }
        if (toFilter && toFilter.isValid() && toFilter.diff(image.createdMoment) < 0) {
          return false;
        }
      }

      if (
        view.filter &&
        (match(image.date) ||
          match(image.name) ||
          match(image.state) ||
          match(image.addedByCompany) ||
          match(image.detailText) ||
          match(image.positionText))
      ) {
        return true;
      }
      if (view.filter) {
        return false;
      }

      return true;
    });

    return visibleImages;
  }

  getImagesByGroups = (visibleImages) => {
    // Get image groups
    const coordinateImages = visibleImages.filter((image) => image.coordinates);

    // Get unique group names
    const groupNames = Array.from(new Set(coordinateImages.map((image) => image.group)));

    const imagesByGroups = [];
    groupNames.forEach((groupName) => {
      // Filter original array to get objects with matching group name
      const groupImages = coordinateImages.filter((ogImg) => ogImg.group === groupName);

      // Create a new object with the groupName, images, and coordinates
      const group = {
        groupName: groupName,
        images: groupImages,
        coordinates: groupImages[0].coordinates, // is this ok?
      };

      imagesByGroups.push(group);
    });

    return imagesByGroups;
  };

  getImageData = (id) => {
    // This needs to match string and number.. I think. TODO: Test
    // eslint-disable-next-line
    return this.state.allImages.find((x) => x.id == id);
  };

  getGroupData = (groupName) => {
    if (groupName === null) {
      // Groupless images have value null, and we don't want them here
      return null;
    }
    return this.state.imagesByGroups.find((group) => group.groupName === groupName);
  };

  getActiveGroupImages = (activeGroup) => {
    const imagesData = [];
    activeGroup.images.forEach((image) => {
      imagesData.push(this.getImageData(image.id));
    });
    return imagesData;
  };

  // TODO: refactor activateGroup and activateImage to one function
  activateGroup = (group, options) => {
    // TODO: Refactor code so that type check is not necessary
    const config = options || {};
    if (!group) {
      const view = { ...this.state.view };
      view.activeImage = null;
      view.activeGroup = null;
      this.setDefaultMarkerIcons();
      this.setState({ view, activeMarker: false });
      this.updateUrl(view);
      return;
    }

    let activeMarker = false;
    const viewElement = document.getElementById("summary-view");
    const { view } = this.state;

    view.activeGroup = group.groupName;
    view.activeImage = null;

    if (this.context.mapViewActive) {
      const marker = window._markers && window._markers.find((m) => m.label === "R" + group.images[0].counter);

      if (marker) {
        if (!config.dontCenter) {
          window._map.setCenter(marker.getPosition());
          if (window._map.zoom < 18) {
            window._map.setZoom(18);
          }
        }
        this.setDefaultMarkerIcons();
        marker.setIcon({
          path: "M12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
          fillColor: "yellow",
          fillOpacity: 1,
          strokeWeight: 1,
          strokeColor: "white",
          rotation: 0,
          scale: 2,
          labelOrigin: new window.google.maps.Point(12, 10),
        });
      }
      activeMarker = marker ? marker.label : false;
    }

    this.setState({
      view,
      activeMarker,
      forceMapview: viewElement.classList.contains("map-view-active"),
    });
    this.updateUrl(view);
  };

  activateImage = (image, options) => {
    // TODO: Refactor code so that type check is not necessary
    const config = options || {};
    if (!image) {
      const view = { ...this.state.view };
      view.activeImage = null;
      this.setDefaultMarkerIcons();
      this.setState({ view, activeMarker: false });
      this.updateUrl(view);
      return;
    }

    let activeMarker = false;
    const viewElement = document.getElementById("summary-view");
    const { view } = this.state;
    view.activeGroup = null;
    view.activeImage = image.id;

    if (this.context.mapViewActive) {
      const marker = window._markers && window._markers.find((m) => m.label === image.counter);

      if (marker) {
        if (!config.dontCenter) {
          window._map.setCenter(marker.getPosition());
          if (window._map.zoom < 18) {
            window._map.setZoom(18);
          }
        }
        this.setDefaultMarkerIcons();
        marker.setIcon({
          path: "M12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
          fillColor: "yellow",
          fillOpacity: 1,
          strokeWeight: 1,
          strokeColor: "white",
          rotation: 0,
          scale: 2,
          labelOrigin: new window.google.maps.Point(12, 10),
        });
      }
      activeMarker = marker ? marker.label : false;
    }

    this.setState({
      view,
      activeMarker,
      forceMapview: viewElement.classList.contains("map-view-active"),
    });
    this.updateUrl(view);
  };

  onChange = (e) => {
    const view = { ...this.state.view };
    view.filter = e.target.value;
    const visibleImages = this.getVisibleImages(view);
    const imagesByGroups = this.getImagesByGroups(visibleImages);
    this.setState({ visibleImages, view, imagesByGroups });
    this.updateUrl(view);
  };

  onChangeFrom = (e) => {
    const view = { ...this.state.view };
    view.filterFromDate = e.target.value;
    const visibleImages = this.getVisibleImages(view);
    const imagesByGroups = this.getImagesByGroups(visibleImages);
    this.setState({ visibleImages, view, imagesByGroups });
    this.updateUrl(view);
  };

  onChangeTo = (e) => {
    const view = { ...this.state.view };
    view.filterToDate = e.target.value;
    const visibleImages = this.getVisibleImages(view);
    const imagesByGroups = this.getImagesByGroups(visibleImages);
    this.setState({ visibleImages, view, imagesByGroups });
    this.updateUrl(view);
  };

  toggleMapView = (e) => {
    const viewElement = document.getElementById("summary-view");
    viewElement.classList.toggle("map-view-active");

    const root = document.getElementById("root");
    root.classList.remove("mobile-map-fullscreen");
  };

  viewImageDetailsInMobileMapview = (e) => {
    this.toggleMapView(e);
    const element = document.querySelector(".image-operations");
    element.scrollIntoView();
  };

  setDefaultMarkerIcons = (e) => {
    if (this.context.mapViewActive) {
      window._markers.forEach((m) => {
        const companyImage = this.state.allImages.filter((image) => {
          return image.counter === m.label;
        })[0];

        let colorIndex;
        companyImage.keywords.map((word) => {
          if(word.text === companyImage.state){
            colorIndex = word.id;
          }
        });

        let customColor = this.props.configuration.summaryviews.keywordColors[colorIndex] ? this.props.configuration.summaryviews.keywordColors[colorIndex] : null;


        m.setIcon({
          path: "M12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
          fillColor: customColor ?? "#ea4335",
          fillOpacity: 1,
          strokeWeight: 1,
          strokeColor: "white",
          rotation: 0,
          scale: 2,
          labelOrigin: new window.google.maps.Point(12, 10),
        });
      });
    }
  };

  // Generates random light color
  generateColor = () => {
    let color = "#";
    for (let i = 0; i < 3; i++)
      color += ("0" + Math.floor(((1 + Math.random()) * Math.pow(16, 2)) / 2).toString(16)).slice(-2);
    return color;
  };

  renderProjectStatus = () => {
    return "";
  };

  render() {
    if (this.state.error) {
      return <Error error={this.state.error} />;
    }
    if (this.state.loading) {
      return <Loading />;
    }

    const isMarine = false;
    const isInfra = true;

    const activeImage = this.getImageData(this.state.view.activeImage);
    const activeGroup = this.getGroupData(this.state.view.activeGroup);
    const activeGroupImages = activeGroup ? this.getActiveGroupImages(activeGroup) : null;

    const classInject = isInfra && this.state.forceMapview ? "map-view-active" : ""; // TODO extract variable
    const visibleImagesKey = this.state.visibleImages.map((vi) => vi.id).join("-");

    const user = LocalStorageService.getUser();
    const canManage = user.hasPermission(AvainiaPermissions.ProjectsManage);

    const { tab } = this.context;
    const mapIsVisible = this.context.mapViewToggleEnabled ? this.context.mapViewActive : this.state.initialized;

    const width = document.documentElement.clientWidth;
    const isMobile = width < 1025;

    return (
      <>
        {tab === Tabs.base && (
          <div id="summary-view" className={classInject}>
            {isInfra && (
              <>
                <SummaryMap
                  key={visibleImagesKey}
                  activateImage={this.activateImage}
                  activateGroup={this.activateGroup}
                  viewImageDetailsInMobileMapview={this.viewImageDetailsInMobileMapview}
                  markerColors={this.state.markerColors}
                  changeColorBy={this.changeColorBy}
                  colorBy={this.state.colorBy}
                  colors={this.state.colors}
                  companies={this.state.companies}
                  imageSubjects={this.state.imageSubjects}
                  configuration={this.props.configuration}
                  allImages={this.state.allImages}
                  activeImage={activeImage}
                  hideableMap={this.context.mapViewToggleEnabled}
                  mapHidden={!mapIsVisible}
                  coordinates={false}
                  imagesByGroups={this.state.imagesByGroups}
                />
                <div className="summary-heading-container width-limiter">
                  <div className="project-heading">
                    <div className="project-heading-title">
                      <h4 className="image-title">{this.state.summaryView.name}</h4>
                      <span className="hilight-text">{this.renderProjectStatus()}</span>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="summary-filter-container width-limiter">
              <SummaryTable
                allPhases={this.state.allPhases}
                configuration={this.props.configuration}
                documentActivate={this.activateImage}
                documentActive={activeImage}
                documents={this.state.allImages}
                folders={[]}
                layout={this.state.view.layout}
                hideOperations={true}
                phases={this.state.phases}
                project={this.state.project}
                resetFilters={this.resetFilters}
                states={this.state.states}
                subjects={this.state.subjects}
                thing={this}
                toggleLayout={this.toggleLayout}
                useFilters
                useIcons
                visibleDocuments={this.state.visibleImages}
              />
            </div>
          </div>
        )}

        {tab === Tabs.summaryCompanies && <SummaryCompanies project={this.props.match.params} />}

        {/* Groupless markers, also this is a bit non-DRY */}
        {activeImage && !activeGroup && (
          <SummaryModalViewGroup
            key={activeImage ? activeImage.id : "0"}
            isMobile={isMobile}
            parent="infraproject"
            activeGroup={null}
            activeImage={activeImage}
            documentEditedCallback={() => {
              document.location.reload();
            }}
            documentDeletedCallback={() => {
              document.location.reload();
            }}
            summaryView={this.props.match.params.id}
            hideModal={this.activateImage}
            show={true}
            configuration={this.props.configuration}
            fields={this.state.fields}
            phases={this.state.phases}
            subjects={this.state.subjects}
            states={this.state.states}
            activeGroupImages={activeGroupImages}
          />
        )}
        {/* Group markers */}
        {activeGroup && (
          <SummaryModalViewGroup
            key={activeGroup ? activeGroup.groupName : "0"}
            isMobile={isMobile}
            parent="infraproject"
            activeGroup={activeGroup}
            activeImage={activeImage}
            documentEditedCallback={() => {
              document.location.reload();
            }}
            documentDeletedCallback={() => {
              document.location.reload();
            }}
            summaryView={this.props.match.params.id}
            hideModal={this.activateGroup}
            show={true}
            configuration={this.props.configuration}
            fields={this.state.fields}
            phases={this.state.phases}
            subjects={this.state.subjects}
            states={this.state.states}
            activeGroupImages={activeGroupImages}
          />
        )}
      </>
    );
  }
}
export default withRouter(SummaryView);
